<template>
		<Header/>
		<main class="content">
			<section class="container mx-auto mt-12 md:mt-20 relative">
				<div class="max-w-2xl mx-auto mb-10 md:mb-12">
					<NuxtPicture v-if="data.data?.attributes.company.data?.attributes.logo.data?.attributes.hash"
								 
								 loading="eager"
								 preload
								 :src="data.data.attributes.company.data?.attributes.logo.data?.attributes.hash"
								 :imgAttrs="{
                                class:'w-auto h-5 mb-2',
                                
                            }"
					/>
					<h1 class="font-medium text-2xl md:text-4xl mb-4 text-balance"> {{ data.data.attributes.title }} </h1>
					<div class="flex text-sm items-center gap-1">
						<h5>{{ data.data.attributes.company.data?.attributes.name }}</h5>
						<span class="text-black/50">|</span>
						<p class="text-black/50">{{data.data.attributes.company.data?.attributes.founded}}</p>
					</div>
					<div v-if="data.data.attributes.lead" class="text-xl mt-4">
						<MarkdownRenderer :markdown="data.data.attributes.lead" />
					</div>

				</div>
				<div class="w-full h-[400px] md:h-[500px] relative overflow-hidden rounded-lg">
					<div class="absolute top-8 right-8 z-10">
						<svg xmlns="http://www.w3.org/2000/svg" width="80" height="120" viewBox="0 0 80 120" fill="none">
							<path d="M39.9997 89.5238C23.1681 89.5238 9.52344 103.168 9.52344 120" stroke="white" stroke-width="19.0476"/>
							<path d="M9.52378 40.0001C9.52378 56.8316 23.1684 70.4762 40 70.4762" stroke="white" stroke-width="19.0476"/>
							<path d="M70.4762 39.9999C70.4762 23.1684 56.8316 9.52376 40 9.52376" stroke="white" stroke-width="19.0476"/>
						</svg>
					</div>
					<NuxtPicture v-if="data.data?.attributes.image.data?.attributes.hash"
								 sizes="100vw md:720px lg:1216px"
								 loading="eager"
								 preload
								 :src="data.data?.attributes.image.data?.attributes.hash"
								 :imgAttrs="{
                          class:'absolute w-full h-full object-cover',
                          
                       }"
					/>
				</div>
			</section>
			<component
				v-for="(block, index) in data.data?.attributes.body"
				:key="index"
				:index="parseInt(index)"
				:is="getComponentName(block)"
				:data="block"
			/>
		</main>
		<section class="container mx-auto mt-12 md:mt-24 grid grid-cols-2 gap-10">
			<div>
				<NuxtLink class="flex flex-col items-start justify-start" v-if="cases?.previousObject" :to="'/' + cases.previousObject?.attributes.category.data?.attributes.slug + '/' + cases?.previousObject?.attributes.slug">
					<span class="text-black/40 text-sm">Previous case</span>
					<h4 class="mt-8 font-medium text-2xl md:text-4xl text-balance">{{ cases?.previousObject?.attributes.company.data?.attributes.name }}</h4>
				</NuxtLink>
			</div>
			<div class="w-full flex justify-end">
				<NuxtLink class="flex flex-col items-end justify-start" v-if="cases?.nextObject" :to="'/' + cases?.nextObject?.attributes.category.data?.attributes.slug + '/' + cases?.nextObject?.attributes.slug">
					<span class="text-black/40 text-sm">Next case</span>
					<h4 class="mt-8 font-medium text-2xl md:text-4xl text-end text-balance">{{ cases?.nextObject?.attributes.company.data?.attributes.name }}</h4>
				</NuxtLink>
			</div>
		</section>
		<Footer/>
</template>

<script lang="ts">
import MarkdownRenderer from '../components/components/MarkDown.vue';
import componentImports from "../assets/js/blogComponentImports.js";

export default {
	components: {
		...componentImports,
		MarkdownRenderer
	},
};
</script>

<script setup lang="ts">
import {useAsyncData, useFetch, useRoute} from "nuxt/app";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const { data, pending } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/cases/' + route.meta.ID + '?populate=deep');
const { data:cases, pending:pendingCases } = useAsyncData('case-nextprev:' + route.meta.ID, async () => {
	const cases = await $fetch(runtimeConfig.public.STRAPI_API_URL + '/cases?populate=deep');

	const filteredData = cases['data'].filter((item) => {
		return item.attributes.category.data.attributes.slug == route.meta.category;
	});

	const index = filteredData.findIndex(item => item.id === route.meta.ID);
	let nextObject = null;
	let previousObject = null;

	if (index !== -1 && index + 1 < filteredData.length) {
		nextObject = filteredData[index + 1];
	}

	if (index !== -1 && index - 1 >= 0) {
		previousObject = filteredData[index - 1];
	}

	return {previousObject: previousObject, nextObject: nextObject}
});

const seoData = data?.value?.data?.attributes?.seo || {};
const { data: globalSeoData } = await useFetch(runtimeConfig.public.STRAPI_API_URL + '/global-seo-setting?populate=deep');
const seoDataNoIndex = seoData.noIndex ?? false;
const seoDataTitle = seoData.pageTitle;
const seoDataDescription = seoData.shortDescription;
const seoDataCanonicalUrl = seoData.canonicalURL
const seoDataOgTitle = seoData.ogTitle ?? seoDataTitle
const seoDataOgDescription = seoData.ogDescription  ?? seoDataDescription
const seoDataOgImage = seoData?.image?.data ?? globalSeoData?.value?.data?.attributes?.ogImage.data;
const seoDataOgImageUrl = seoDataOgImage.attributes.url;



useSeoMeta({
  title: () => seoDataTitle,
  ogTitle: () => seoDataOgTitle,
  description: () => seoDataDescription,
  ogDescription:  () => seoDataOgDescription,
  ogImage: () => seoDataOgImageUrl,
})

useHead({
  meta: [
    seoDataNoIndex != false ? { name: 'robots', content: 'noindex, follow' } : {},
  ],
  script: [
    seoData.Schema != null ? { type: 'application/ld+json', innerHTML: seoData.Schema } : {},
    globalSeoData.value.data.attributes.globalSchema != {} ? { type: 'application/ld+json', innerHTML: globalSeoData.value.data.attributes.globalSchema } : {},
  ]
})

const getComponentName = ((block: any) => {
	let blockName = block.__component.replace("blocks.", "");
	return blockName.split('-')
		.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
		.join('');
});

const formattedDate = ((originalDate: any) => {
	const dateObject = new Date(originalDate);
	return dateObject.toLocaleDateString("en-US", {
		year: "numeric",
		month: "long",
		day: "numeric"
	});
});

</script>
